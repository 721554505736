import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Learning`}</h1>
    <h2>{`CTL course`}</h2>
    <h2>{`RDS Modules / workshop`}</h2>
    <Link to="/learning/rds-modules" mdxType="Link">RDS Module</Link>
    <h2>{`HIST-APMA course “Data: Past, Present and Future”`}</h2>
    <p>{`Taught by `}<a parentName="p" {...{
        "href": "https://history.columbia.edu/faculty/jones-matthew-l/"
      }}>{`Matt Jones`}</a>{` and `}<a parentName="p" {...{
        "href": "http://www.columbia.edu/~chw2/"
      }}>{`Chris Wiggins`}</a>{`, this
`}<a parentName="p" {...{
        "href": "https://doc.search.columbia.edu/classes/data+past+present?site=Directory_of_Classes"
      }}>{`course`}</a>{`
“moves from the birth of statistics in the 18th century to the surveillance
capitalism of the present day, covering racist eugenics, World War II
cryptography, and creepy personalized advertising along the way. Rather than
looking at ethics and history as separate from the science and engineering,
the course integrates the teaching of algorithms and data manipulation with
the political whirlwinds and ethical controversies from which those techniques
emerged.” `}<a parentName="p" {...{
        "href": "https://data-ppf.github.io/"
      }}><strong parentName="a">{`Course Website`}</strong></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      